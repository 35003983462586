import { combineReducers } from 'redux';
import auth from 'redux/reducers/auth';
import customizer from 'redux/reducers/customizer';
import dashboard from 'redux/reducers/dashboard';
import navbar from 'redux/reducers/navbar/Index';

const rootReducer = combineReducers({
  customizer,
  auth,
  navbar,
  dashboard,
});

export default rootReducer;
